export const data = [
  {
    to: "/",
    text: "Home",
    id: "#home",
  },
  {
    to: "/Features",
    text: "Features",
    id: "#features",
  },
  {
    to: "/About",
    text: "About",
    id: "#about",
  },

  {
    to: "/contact",
    text: "Contact",
    id: "#contact",
  },
];
